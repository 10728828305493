@import './fonts';

// body {
//   & > * + * {
//     margin-top: 16px;
//   }
// }

// NEW PRIMARY BTN

// button-variant(background, border-color, hover:background-color, hover:border-color, active:background-color, active:border-color)

.btn-primary {
  @include button-variant($red, $red, darken($red, 10%), darken($red, 10%), lighten($red, 10%), lighten($red, 10%));
  border: 1px solid $red;

  //line-height: 100%;
  //padding: 18px 22px 11px;
  text-transform: uppercase;
  font-weight: 600;
}

.btn {
  border-radius: 0.5rem;
  font-family: $headings-font-family;
  letter-spacing: 0.1rem;
}

.btn,
.nav-item {
  text-transform: uppercase;
}

.shoppingcart-finisher {
  background-color: rgba($pink, 0.8);
}

.sticky-bottom.is-valid.when-valid .btn-primary,
.shoppingcart-finisher .btn-primary {
  transition: all 300ms ease;
  &:hover {
    letter-spacing: 3px;
  }
}

.media-heading {
  font-family: $font-family-sans-serif;
}

body.product-listing-page {
  #main {
    background-color: $gray-100;
  }
  #product-listing {
    min-height: 50vh;
  }
}

body.merch-listing-page {
  #main {
    //background-color: $red;
  }
  #product-listing {
    min-height: 50vh;
  }
  .superh1 {
    font-size: 3em;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px #fff;
  }
}

body.product-detail-page {
  #main {
    //background-color: $green;
  }

  .carousel-inner {
    box-shadow: $box-shadow-xl;
    border-radius: 0.5rem;
    background-color: lighten($white, 5%);
  }
  .carousel-item-content img {
    border-radius: 0.5rem;
  }
  .carousel-indicators {
    @include media-breakpoint-down(md) {
      display: none;
    }
    position: relative;
    bottom: -10px !important;
  }
  .product-thumb {
    background-color: transparent !important;
    border-radius: $border-radius;
    height: 60px;
    width: 60px;
    margin: 5px;
  }

  .container.detail-page {
    //color: $white;
  }
  .carousel-control-prev,
  .carousel-control-next {
    color: $red !important;
  }

  .carousel-indicators {
    @include media-breakpoint-down(md) {
      display: none;
    }
    position: relative;
    bottom: 0;
  }
  .product-thumb {
    background-color: transparent !important;
    border-radius: 0.5em;
    height: 60px;
    width: 60px;
    margin: 5px;
  }
  header {
    // @include media-breakpoint-down(md) {
    //   margin-top: 80px !important;
    // }
  }
}

.product-content {
  background: transparent;
  a {
    //color: $white;
  }
  a:hover {
    //color: rgba($white, 30%);
  }
}
html {
  font-size: 12pt;
}

#root {
  padding-top: 0;
}

#footer {
  background: $black;
  font-size: 1rem;
  min-height: auto;
  color: $white;
  a {
    color: $white;
  }
  .copyright {
    font-size: 0.8rem;
    line-height: 1rem;
  }
  .copyright-logos {
    img {
      max-height: 2rem;
      width: 250px;
    }
    margin-top: 25px;
  }
}

// .headerimage {
//   background: $body-bg url('./assets/images/maex_ginhead_sm.jpg') no-repeat center top;
//   background-size: cover;
//   height: 35vh;
//   width: 100vw;
//   display: flex;
//   justify-content: flex-end;
//   align-items: flex-end;
//   @include media-breakpoint-up(md) {
//     height: 50vh;
//     background: $body-bg url('./assets/images/maex_ginhead.jpg') no-repeat center top;
//     background-size: cover;
//   }
//   @include media-breakpoint-up(lg) {
//     height: 70vh;
//     background: $body-bg url('./assets/images/maex_ginhead.jpg') no-repeat center top;
//     background-size: cover;
//     //margin-top: -$calculated-navbar-height;
//   }
//   .headerimage-content {
//     width: 75vw;
//     @include media-breakpoint-down(sm) {
//       width: 100vw;
//       padding: 20px !important;
//     }
//     h1 {
//       color: $white;
//     }
//   }
// }

// .headerimage-tour {
//   background: $body-bg url('./assets/images/maex_head_tour.jpg') no-repeat center top;
//   background-size: cover;
//   height: 35vh;
//   width: 100vw;
//   display: flex;
//   justify-content: flex-end;
//   align-items: flex-end;
//   @include media-breakpoint-up(md) {
//     height: 50vh;
//     background: $body-bg url('./assets/images/maex_head_tour.jpg') no-repeat center center;
//     background-size: cover;
//   }
//   @include media-breakpoint-up(lg) {
//     height: 70vh;
//     background: $body-bg url('./assets/images/maex_head_tour.jpg') no-repeat center center;
//     background-size: cover;
//     //margin-top: -$calculated-navbar-height;
//   }
//   .headerimage-content {
//     width: 75vw;
//     @include media-breakpoint-down(sm) {
//       width: 100vw;
//       padding: 20px !important;
//     }
//     h1 {
//       color: $white;
//     }
//   }
// }

// .masonry .maki-container {
//   align-items: flex-start;
// }

// .maki.card .card-body {
//   padding: $card-spacer-x;

//   .card-title {
//     @extend .text-truncate;
//     .title {
//       @extend .text-truncate;
//       font-weight: 900;
//     }
//   }
// }

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  font-weight: 700 !important;
  text-transform: uppercase;
}

h1.superhead {
  @include font-size(10rem);
  //text-shadow: 1px 1px 1px black;
  color: $gray-200 !important;
}

.nav-item {
  @include media-breakpoint-up(md) {
    font-size: 1rem;
  }
}

.nav-link {
  letter-spacing: 1px;
  font-weight: 500;
  transition: all 300ms ease;
  &:hover {
    letter-spacing: 3px;
  }
}

#main-menu {
  background: transparent;

  .nav-link {
    background-color: $black; //$light-milk;
    color: $white;
    margin-bottom: 0.4rem;
    font-weight: 500;
    text-transform: uppercase;
  }
}

.secondary-menu .menu {
  display: none;
  @include media-breakpoint-up(lg) {
    display: flex;
  }
}

#header > .navbar {
  height: $calculated-navbar-height-sm;
  box-shadow: $box-shadow-lg;
  background: $black !important;
  @include media-breakpoint-up(md) {
    height: $calculated-navbar-height;
    font-size: 22px;
    padding-left: 2vw;
    padding-right: 2vw;
  }

  .nav-link {
    color: $white;
  }

  .navbar-brand {
    padding: 0 15px;
    @include media-breakpoint-down(sm) {
      font-size: 1.1rem;
    }
    img {
      height: 3rem;
      width: auto;
      max-height: 3rem;
    }
    @include media-breakpoint-up(md) {
      img {
        height: 3rem;
        width: auto;
        max-height: 3rem;
      }
    }
  }
  .dropdown-menu {
    background-color: rgba($black, 0.9);
  }
}

.offcanvas-collapse {
  top: $calculated-navbar-height + 1rem;
  @include media-breakpoint-down(sm) {
    top: $calculated-navbar-height-sm + 1rem;
  }
}

video {
  background: $dark;
}

#cart-menu,
#main-menu {
  font-size: 16px;
}
#main-menu {
  bottom: auto;
  overflow: visible;
}

.logo-container {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: $black;
  pointer-events: none;
}

// .logo-name {
//   // font-size: 18px;
//   @include media-breakpoint-up(md) {
//     font-size: 40px;
//   }
//   font-weight: 900;
//   font-style: normal;
// }
// .logo-branch {
//   @extend .d-none;
//   @extend .d-md-inline;
//   font-size: 30px;
//   font-weight: 500;
//   font-style: normal;
//   color: rgba(0, 0, 0, 0.5);
//   position: relative;
//   padding-left: 30px;

//   &::before {
//     position: absolute;
//     content: '';
//     left: 15px;
//     top: 50%;
//     transform: translateY(-50%);
//     width: 3px;
//     height: 35px;
//     background-color: currentColor;
//   }
// }

.product-listing {
  background: $info;
}

// MAKIS IN DA HOOD

.maki.card {
  overflow: hidden;
  background-color: transparent;
  border-radius: 0.5rem;
  box-shadow: $box-shadow-lg;
  background-color: $white;

  .card-title {
    display: block;
    text-align: left;
    padding-bottom: 0rem;
    padding-left: 1rem;
    padding-right: 1rem;

    .title {
      font-weight: 700 !important;
      font-family: $headings-font-family;
      font-size: $h4-font-size;
      text-transform: uppercase;
      //padding-bottom: 0.8rem;
      //font-size: 13px;
      display: block;
      width: 100%;
    }

    .sub-title {
      font-size: $font-size-sm;
      display: block;
      width: 100%;
      color: $gray-600;
    }

    .price {
      font-weight: bold;
      display: inline-block;
    }

    .former-price {
      text-decoration: line-through;
      display: inline-block;
      //font-size: 13px;
      //line-height: 1.6rem;
      color: $secondary !important;
    }
  }

  .maki-img-container {
    overflow: hidden;
    background-color: #fff;
  }

  .card-header {
    color: $gray-900;
  }
}

.product-detail-title {
  font-style: normal;
  font-size: 3rem;
  @include media-breakpoint-up(md) {
    //font-size: 55px;
  }
  position: relative;
  margin-top: 0;
  margin-bottom: map-get($spacers, 4);

  small  {
    font-family: $font-family-sans-serif;
    font-size: 1.5rem;
    margin-top: 0.5rem;
    text-transform: none;
  }

  // &::before {
  //   content: '';
  //   display: block;
  //   position: absolute;
  //   left: 50%;
  //   bottom: map-get($spacers, 2) * -1;
  //   width: 85px;
  //   height: 3px;
  //   background-color: $black;
  //   transform: translateX(-50%);

  //   @include media-breakpoint-up(md) {
  //     left: 0;
  //     width: 200px;
  //     transform: translateX(0);
  //   }
  // }
}

// MAKI 2020 OVERWRITES

.maki-2020 {
  &,
  &:link,
  &:hover,
  &:focus,
  &:active {
    color: $card-color;
    text-decoration: none;
  }
  flex: 1;
  box-shadow: none;
  overflow: hidden;
  transition: box-shadow 0.2s ease;
  border: 0;

  .maki-img,
  .maki-img-hover {
    transition: opacity 0.5s;
  }

  .maki-img-hover {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: none;
  }

  &:hover {
    // .maki-img {
    //   opacity: 0.75;
    // }
    .maki-img-hover {
      opacity: 1;
    }
  }

  .card-body {
    background: transparent;
    backdrop-filter: none;

    .card-title {
      color: $white;
    }

    text-align: center;
    position: relative;
    box-shadow: none;
    transition: box-shadow 0.2s ease;

    .overlay {
      position: absolute;
      top: auto;
      bottom: 92%;
      left: -10%;
      right: -10%;
      display: flex;
      justify-content: center;
      padding: $badge-padding-y $badge-padding-x;
      transform: rotate(-2deg);
      background: fade-out($yellow, 0.9);
      color: $white;
      opacity: 1;
      transition: all 0.2s;
      // @extend .small;
    }

    .sub-title {
      display: none;
    }

    small  {
      font-family: $font-family-sans-serif;
      text-transform: none;
    }
  }

  .card-img-overlay {
    top: auto;
    padding: 0;
    // max-height: 100%;
    // overflow-y: auto;
  }

  // .active-menu {
  // }

  &:hover,
  &:focus,
  &:active {
    box-shadow: $box-shadow-lg;
    .overlay {
      //opacity: 0.7;
    }
    .card-body {
      box-shadow: $box-shadow-inverse-xl;
      background: fade-out($pink, 0.15);
      .card-title {
        color: $black;
      }
    }
  }
}

// safari fix from-to dates on nigiri
@include media-breakpoint-up(lg) {
  .nigiri-date {
    text-align: center;
    flex-direction: column;
  }

  .nigiri-date * + * {
    margin-top: -1rem;
  }
}

// GIN + GLAS

#article-42476 {
  .maki-2020 .card-body .card-title {
    color: $black !important;
  }
}

#article-42477 {
  .maki-2020 .card-body .card-title {
    color: $black !important;
  }
}

// TAGS

// 3491 = "sale" tag
.maki.tag-id-7900 {
  &::after {
    content: 'SALE';
    background-color: $red;
    color: $white;
    padding: 0.25rem 0.75rem;
    font-weight: bold;
    display: inline-block;
    position: absolute;
    z-index: 1;
    top: 15px;
    right: -2px;
    transform: translate(-50%, -50%);
    transform: rotate(-4deg);
    // width: 80px;
    // height: 80px;
    //background: $af-yellow url('./assets/images/po.svg') no-repeat center center;
    //background-size: contain;
  }
  &:hover {
    &::after {
      opacity: 0.8;
    }
  }
}

// 3491 = "sale" tag
.maki-2020.tag-id-5705 {
  &::before {
    //@extend .rounded-circle;
    content: 'EXKLUSIV';
    background-color: $yellow;
    color: $white;
    padding: 0.25rem 0.75rem;
    font-weight: bold;
    display: inline-block;
    position: absolute;
    z-index: 1;
    top: 15px;
    right: -2px;
    transform: translate(-50%, -50%);
    transform: rotate(-4deg);
    // width: 80px;
    // height: 80px;
    //background: $af-yellow url('./assets/images/po.svg') no-repeat center center;
    //background-size: contain;
  }
  &:hover {
    &::after {
      opacity: 0.8;
    }
  }
}

// JUMBOHEAD

.jumbohead {
  height: 65vh;
  background: $black url('./assets/images/miw_box.jpg') no-repeat left center;
  background-size: cover;
  box-shadow: $box-shadow-xl;
  img {
    max-width: 80%;
    transition: all 0.2s ease;
  }
  &:hover {
    img {
      transform: rotate(-3deg);
      transition: all 0.2s ease;
    }
  }
}

.jumbohead.tour {
  height: 65vh;
  background: $black url('./assets/images/header.jpg') no-repeat center center;
  background-size: cover;
  box-shadow: none;
  img {
    max-width: 90%;
    transition: all 0.2s ease;
    margin-bottom: 3rem;
    @include media-breakpoint-up(md) {
      max-width: 70%;
    }
  }
  &:hover {
    img {
      transform: none;
      transition: all 0.2s ease;
    }
  }
}

// TICKETS

#ticket-listing,
.ticket-listing-page {
  #header {
    box-shadow: $box-shadow-xl;
  }

  background-color: $black;
  .ticket-header-image {
    // background-image: url('./assets/images/ticket-header-image.jpg');
    background-position: center;
    background-size: cover;
    margin: 0;
    padding: 0;
    height: 50vh;
    // height: calc(100vh - 72px);
    // @include media-breakpoint-up(md) {
    //   height: calc(100vh - 60px);
    // }
  }

  .list-page {
    padding-top: 3vh;

    // padding-left: 15vw;
    // padding-right: 15vw;
    // @include media-breakpoint-down(md) {
    //   padding-left: 8vw;
    //   padding-right: 8vw;
    // }
  }

  .nigiri.card {
    background-color: $white;
    border-radius: 0.5rem;

    //border-bottom: 1px solid rgba($white, 0.25);
    .nigiri-content {
      padding: 1.25rem;
      margin-top: 0.2rem;
      color: $black;
      @include media-breakpoint-up(md) {
        align-items: center;
      }
      @include media-breakpoint-down(md) {
        display: table;
      }
    }
    .nigiri-date {
      font-size: $h3-font-size;
      color: $primary;
      align-items: center !important;
      font-weight: bold;

      font-family: $headings-font-family;
      text-transform: uppercase;

      @include media-breakpoint-down(md) {
        font-size: $h5-font-size;
      }
    }
    .nigiri-body {
      font-size: $h2-font-size;
      border-left: 0;
      text-transform: uppercase;
      font-weight: bold;
      padding-left: 3rem;
      @include media-breakpoint-down(md) {
        padding-left: 0;
        line-height: 2rem;
      }
    }
    .badge {
      z-index: 2;
      font-size: 0.8em;
    }
    .title {
      font-size: $font-size-base * 1.2;
      margin-bottom: 0;
      color: $primary;
      font-family: $font-family-sans-serif !important;
    }
    .subtitle {
      font-size: $font-size-sm;
      margin-bottom: 0;
      color: $gray-600;
      font-weight: normal;
      text-transform: none;
      @include media-breakpoint-down(md) {
        line-height: 1rem;
        margin-bottom: 0.5rem;
      }
    }
    .location {
      font-family: $headings-font-family;
      font-weight: 600 !important;
      font-size: $h3-font-size !important;
      text-transform: uppercase;
      color: $primary;
      @include media-breakpoint-down(md) {
        margin-bottom: 1rem;
      }
    }
    &:hover {
      background-color: lighten($secondary, 45%);
    }
  }
}

// TICKET DETAILS

.ticket-detail-page {
  .blurry-background-image {
    //height: 50vh;
    background-color: $black;
  }

  .blurry-background-image:before {
    filter: blur(1.5rem);
    opacity: 0.8;
    background-position: center center;
    display: none;
  }

  .invisible-title {
    position: absolute;
    left: -20000px;
    top: 0;
  }

  // .blurry-background-image:before {
  //   background-image: none;
  // }
  .carousel-inner {
    //display: none !important;
  }

  .article-title {
    padding-left: 0;
    padding-right: 0;
    background: transparent !important;
    border: 0;
    min-height: 0;

    .super-title {
      font-size: 0.7rem;
      opacity: 1;
    }
  }

  .product-info-bar {
    background-color: black;
    color: $white;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 0.1rem solid rgba($black, 0.08);
    box-shadow: none;
    i.far {
      color: $red !important;
    }
    .navbar-text {
      text-align: center;
      margin-left: 0;
      display: block;
    }
  }
  .variant-listing {
    .visible,
    .invisible {
      display: none !important;
    }
  }
}

.checkout .when-valid.is-valid,
form .when-valid.is-valid,
.checkout .when-valid,
form .when-valid,
.shoppingcart-finisher {
  background-color: rgba($secondary, 0.9);
}

.button-sold-out-status_starts_soon {
  background-color: $gray-400;
  border-color: $gray-400;
}

.button-sold-out-status_starts_soon:hover {
  background-color: $gray-500;
  border-color: $gray-500;
}

.button-sold-out-status_sold_out {
  background-color: $gray-400;
  border-color: $gray-400;
}

.btn.btn-primary.button-sold-out-status_sold_out:hover {
  background-color: $gray-500;
  border-color: $gray-500;
}

// G8

body.g8-page {
  background-color: $black;
  color: $white;

  .g8-logo {
    max-width: 200px;
  }

  h1 {
    small {
      font-family: $font-family-sans-serif !important;
      font-size: 1.5rem;
      color: $white;
    }
  }

  .g8-please-wait,
  .g8-current-position,
  .g8-time-remaining,
  .g8-thank-you {
    color: $white;
  }
}

.badge-100 {
  font-size: 100% !important;
}
